@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Imbus";
  src: url("./assets/form-fonts/imbue-v9-latin-ext_latin-regular.ttf")
      format("truetype"),
    url("./assets/form-fonts/imbue-v9-latin-ext_latin-regular.otf")
      format("opentype"),
    url("./assets/form-fonts/imbue-v9-latin-ext_latin-regular.woff")
      format("woff"),
    url("./assets/form-fonts/imbue-v9-latin-ext_latin-regular.woff2")
      format("woff2");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: static;
  font-smooth: never;
}
body.overflow-hidden {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.h2-custom {
  font-size: 2rem;
  line-height: 1.2;
}

.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}

/* width */
.modalContainer::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.modalContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #6b7ca0;
  border-radius: 10px;
}

/* Handle */
.modalContainer::-webkit-scrollbar-thumb {
  background: #2a313f;
  border-radius: 10px;
}

/* Handle on hover */
.modalContainer::-webkit-scrollbar-thumb:hover {
  background: #53678f;
}
.main-content {
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
}
.item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #091e47ad;
  display: none;
}
.not-active {
  cursor: auto;
}
.not-active .item-overlay {
  display: block;
  cursor: auto;
}
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  -webkit-transform: translateZ(0);
  -webkit-font-smoothing: antialiased;
  transform: translateZ(0);
}

.modalContainer {
  width: 95%;
  max-width: 550px;
  min-width: 360px;
  height: 97%;
  min-height: 600px;
  border-radius: 12px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  transform: none !important;
}
.modalContainer.modaThankYou {
  height: 860px;
}

.modalCloseButton {
  width: 38px;
  height: 38px;
  padding: 8px;
  border-radius: 50%;
  position: fixed;
  background: #14284c;
  cursor: pointer;
  z-index: 100;
}

.modalForm {
  border-color: #000;
  border-width: 8px;
  border-style: solid;
  padding: 9rem 1.8rem 1.8rem 1.8rem;
  position: relative;
  margin: 0 auto;
  color: #000;
  font-size: 1.1rem;
  width: 80%;
  border-radius: 3px;
  margin-top: 20rem;
  margin-bottom: 2rem;
  background-color: #fff;
}
.modalForm.contentForm {
  padding: 2rem 1.8rem;
  margin-top: 0;
  width: 60%;
}
.contentFormBg {
  background-position: top center;
  background-size: 105%;
}
.modalPrizeImage {
  position: absolute;
  width: 280px;
  z-index: 1;
  height: 280px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  top: -140px;
}
.modalPrizeTitles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background-color: #091e47ed;
  border: 4px solid;
  flex-direction: column;
  visibility: hidden;
  text-align: left;
}
.modalPrizeTitles ol {
  list-style: auto;
  padding-left: 45px;
  font-size: 14px;
  padding-right: 22px;
  font-weight: 500;
}
.modalPrizeTitles li {
  line-height: 1.2;
  margin-bottom: 10px;
}
.modalPrizeTitles li:last-child {
  margin-bottom: 0;
}
.modalPrizeTitles a.disabled-link {
  pointer-events: none;
}
.modalPrizeTitles strong {
  font-weight: 900;
}
.modalPrizeTitles a:hover {
  text-decoration: underline;
}
.modalPrizeImage:hover .modalPrizeTitles {
  visibility: visible;
}
.starTail {
  position: absolute;
  left: -14%;
  top: -240px;
}

.starHead {
  position: absolute;
  top: -298px;
  right: -40px;
  z-index: 2;
}

.modalFooterGraphic {
  width: 100%;
  height: 127px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.modalSubmit {
  position: absolute;
  bottom: -1.6rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  color: #fff;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  font-weight: 700;
  background-image: url("./assets/img/potvrdi-btn-img.png") !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: 162px;
  height: 45px;
  width: 158px;
}

.basic-button {
  display: inline-block;
  margin-top: 15px;
  font-size: 18px;
  color: #fff;
  padding: 0.5rem 2rem;
  background-color: #000 !important;
  border-radius: 5px;
  font-weight: 900;
  border: 1px solid #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.form-bg {
  background-image: url("./assets/img/form-bg.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 390px !important;
}
/*.basic-button.dobitnici-button {
  background-image: url("./assets/img/dobitnici-btn.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 120px;
  width: 418px;
  background-color: transparent !important;
  margin-left: -130px;
}*/
.basic-button.pravila-button {
  background-image: url("./assets/img/pravila-btn-img.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 158px;
  height: 45px;
  width: 158px;
  background-color: transparent !important;
}

.modalSubmit.loading {
  pointer-events: none;
  filter: brightness(0.8);
}

.fileUpload {
  text-transform: uppercase;
  font-size: 16px;
  color: #fff;
  padding: 0.2rem 2rem;
  font-weight: 700;
  border-radius: 3px;
  background-image: url("./assets/img/ucitaj-btn-img-bingo.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 375px;
  height: 45px;
  width: 373px;
}
/*.fileUpload {
  font-size: 18px;
  color: #fff;
  padding: 0.5rem 2rem;
  background-color: #ca81b6 !important;
  border-radius: 5px;
  font-weight: 700;
  border: 1px solid #fff;
}*/

.formLabel {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  display: block;
  /*font-family: "Noteworthy-Lt";*/
}

.modalForm input,
.modalForm select {
  height: 50px;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  border: 2px solid #000;
}
.modalForm input[type="checkbox"] {
  height: 25px;
  width: 25px !important;
  min-width: 25px;
  accent-color: #000;
}

.modalForm select {
  width: 100%;
  height: 32px;
  color: #000000;
}

.modalForm input:focus {
  outline-style: solid;
  outline-color: #000;
}

.modalForm textarea:focus {
  outline-style: solid;
  outline-color: #000;
}

.modalForm textarea {
  height: 150px;
  color: #000;
  padding: 5px 10px;
  font-size: 14px;
  border: 2px solid #000;
}

.modalForm input:not([type="checkbox"]),
select,
textarea {
  width: 100%;
  border-radius: 8px;
}

.mainContent {
  padding: 3rem 1rem 1rem 1rem;
}

.mainContent h1 {
  font-family: "Imbus";
}

.mainContent h2,
p {
  font-family: "Roboto Condensed";
}

.mainContent p {
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.mainContentStar {
  width: 100%;
  min-width: 300px;
  max-width: 1280px;
  height: intrinsic;
}

.mainContentPrizes {
  width: 100%;
  min-width: 300px;
  max-width: 870px;
}

.formError {
  width: 100%;
  background: #ea3830;
  margin-top: 0.5rem;
  font-family: "Roboto Condensed";
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  border-radius: 3px;
  padding: 8px;
  line-height: 1.2;
}
.fileUpload {
  position: relative;
}
.fileUpload input {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  height: 31px;
  cursor: pointer;
}
@media only screen and (min-width: 992px) {
  .lg\:-mt-8 {
    margin-top: -8rem !important;
  }
}
@media only screen and (max-width: 680px) {
  .mainContent p {
    font-size: 1.1rem;
  }

  .mainContent p {
    margin-bottom: 0.8rem;
  }
  .h2-custom {
    font-size: 1.5rem;
    line-height: 1.1;
  }
  .modalForm.contentForm {
    padding: 2rem 0.8rem;
  }
  .form-bg {
    padding-bottom: 300px !important;
  }
}
@media only screen and (max-width: 850px) {
  .modalForm.contentForm {
    width: 80%;
  }
}
@media only screen and (max-width: 768px) {
  .modalForm {
    margin-top: 17rem;
  }
}

@media only screen and (max-width: 510px) {
  .modalForm {
    width: 90%;
    padding: 8rem 1rem 1.4rem 1rem;
  }

  .modalPrizeImage {
    width: 230px;
    height: 230px;
    top: -115px;
  }
  .modalForm.contentForm {
    width: 97%;
  }

  .fileUpload,
  .modalSubmit {
    font-size: 16px;
  }
}

@media only screen and (max-width: 460px) {
  .starTail {
    position: absolute;
    left: -12%;
    top: -205px;
    width: 90%;
  }

  .starHead {
    position: absolute;
    top: -260px;
    right: -15px;
    z-index: 2;
    width: 64%;
  }

  .modalContainer {
    min-width: 310px;
  }
  .fileUpload {
    background-size: 276px;
    width: 275px;
  }
  .modalSubmit {
    background-size: 148px;
    height: 40px;
    width: 148px;
  }
  .mainContent {
    padding: 3rem 0.5rem 1rem 0.5rem;
  }
  .basic-button.pravila-button {
    background-size: 148px;
    height: 40px;
    width: 148px;
  }
}

@media only screen and (max-width: 350px) {
  .starHead {
    top: -235px;
  }

  .starTail {
    position: absolute;
    left: -12%;
    top: -185px;
  }
  .modalForm.contentForm {
    padding: 5rem 0.8rem;
  }
}
